@font-face {
  font-family: "EyInterstate";
  font-weight: 300;
  src: url(./assets/fonts/EYInterstate/EYInterstate-Light.ttf);
  font-style: normal;
}
@font-face {
  font-family: "EyInterstate";
  font-weight: 700;
  src: url(./assets/fonts/EYInterstate/EYInterstate-Bold.ttf);
  font-style: normal;
}
@font-face {
  font-family: "EyInterstate";
  font-weight: 600;
  src: url(./assets/fonts/EYInterstate/EYInterstate-LightBold.ttf);
  font-style: normal;
}
@font-face {
  font-family: "EyInterstate";
  font-weight: 400;
  src: url(./assets/fonts/EYInterstate/EYInterstate-Regular.ttf);
  font-style: normal;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
input {
  font-family: "EyInterstate";
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 20px;
}
button {
  font-weight: 400;
  font-size: 14px;
  font-family: "EyInterstate";
}
:root {
  --form-control-color: #ffe633;
  --form-control-color-hover: #fdfdfd;
  --form-control-color-checkbox: #000;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  min-height: 100%;
}

body {
  background-color: #2e2e38;
}

.body {
  background-color: #2e2e38;
  max-width: 706px;

  margin-bottom: 48px;
  margin-inline: auto;
  color: white;
}
.logos {
  padding-bottom: 32px;
}
.headingclass {
  display: flex;
  flex-direction: column;
  border-bottom: 1px #51515c solid;
}
.heading {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 20px;
  font-weight: 300;
  line-height: 120%;
  cursor: default;
}
.sub-title {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.5px;
  margin-bottom: 24px;
  color: #c4c4cc;
  font-weight: 300;
  cursor: default;
}
.tab-row {
  display: flex;
  flex-direction: row;
  position: relative;
}
.tabs {
  margin-top: 40px;
  margin-right: 40px;
  position: sticky;
  align-self: flex-start;
  top: 50px;
}
.tab-subtitle {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  min-width: max-content;
  font-size: 10px;
  letter-spacing: -0.5px;
  margin-top: 2px;
  margin-bottom: 4px;
  line-height: 12px;
  color: #c4c4cc;
}
.tab {
  margin-bottom: 25px;
  border-left: 3px rgba(156, 130, 212, 0.3) solid;
  padding: 15px;
}
.module_list_element {
  cursor: default;
  display: flex;
}
.tab-active {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  border-left: 3px #ffe600 solid;
  padding: 15px;
}
.tab-inactive {
  margin-bottom: 8px;
  border-left: 3px rgba(156, 130, 212, 0.3) solid;
  padding: 15px;
  color: #77777d;
}
.tab-title {
  display: flex;
  margin-bottom: 2px;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #77777d;
  width: max-content;
  margin-inline: auto;
}
.tab-title-active {
  font-weight: 700;
  display: flex;
  margin-bottom: 2px;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  width: max-content;
  cursor: default;
  margin-inline: auto;
}
.steps {
  width: 100%;
  min-height: 636px;

  border-left: 1px #51515c solid;
  /* padding-top: 41px; */
  padding-left: 41px;
}
.question_name_container {
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-family: "EyInterstate";

  cursor: default;
}

.top-nav {
  position: sticky;
  top: 0;
  padding-top: 41px;
  padding-bottom: 41px;
  background-color: #2e2e38;
  z-index: 10;
}

.dots {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.current {
  margin-right: 12px;
  background-color: #724bc3;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.past {
  margin-right: 12px;
  background-color: #724bc3;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.next {
  margin-right: 12px;
  background-color: #724bc3;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 70%;
}
.next_small {
  margin-right: 12px;
  background-color: #724bc3;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  opacity: 70%;
}

.question__title {
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 34px;
}
.question__answer {
  display: flex;
  flex-direction: column;
}
.question__container {
  display: flex;
  cursor: pointer;
  min-height: 43px;
  align-items: center;

  border-radius: 4px;
  background-color: #1a1a24;
  margin-bottom: 8px;
}

.question__label {
  align-items: center;

  display: flex;
  width: 100%;
  height: 100%;
  font-family: "EyInterstate";
  font-weight: 300;
  padding-left: 5px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.5px;
  cursor: pointer;
}
.button_next {
  cursor: pointer;
  width: 100%;
  height: 40px;
  color: white;
  background-color: #724bc3;
  border-radius: 5px;
  border: 0px;
}
.button_next:hover {
  opacity: 80%;
}

.button_next:disabled {
  background-color: #77777d;
  pointer-events: none;
}

/* input[type="radio"] {
  outline: none;
  cursor: pointer;
  appearance: none;
  background-color: #1a1a24;
  margin: 0;
  font: inherit;
  color: white;
  width: 16px;
  height: 16px;
  border: 0.15em solid currentColor;
  border-radius: 70%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="radio"]::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 70%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}
input[type="radio"]:checked::before {
  transform: scale(0.95);
}
input[type="radio"]:checked {
  color: var(--form-control-color);
}

input[type="radio"]:hover::before {
  box-shadow: inset 1em 1em var(--form-control-color-hover);
}
input[type="radio"]:hover {
  color: var(--form-control-color-hover);
  transition: all ease 0.2s;
}
.question__label:hover {
  color: var(--form-control-color-hover);
} */

.input_radio {
  position: relative;
  margin-right: 5px;
  top: -1px;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  display: none;
}
.question__label:hover [type="radio"]:checked + .input_radio:before {
  border-color: var(--form-control-color-hover);
}
.question__label:hover [type="radio"]:checked + .input_radio:after {
  background: var(--form-control-color-hover);
}
[type="radio"]:checked + .input_radio:before,
[type="radio"]:not(:checked) + .input_radio:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border: 2px solid var(--form-control-color-hover);
  border-radius: 100%;
  background: transparent;
}
[type="radio"]:checked + .input_radio:before {
  border-color: var(--form-control-color);
}
[type="radio"]:checked + .input_radio:after,
[type="radio"]:not(:checked) + .input_radio:after {
  content: "";
  width: 10px;
  height: 10px;
  background: var(--form-control-color-hover);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transform-origin: top left;
  transition: scale 0.2s ease;
}
[type="radio"]:checked + .input_radio:after {
  background: var(--form-control-color);
}
[type="radio"]:not(:checked) + .input_radio:after {
  opacity: 0;
  scale: 0;
}
[type="radio"]:checked + .input_radio:after {
  opacity: 1;
  scale: 0.97;
}

input[type="checkbox"] {
  outline: none;
  cursor: pointer;
  appearance: none;
  background-color: #2e2e38;
  margin: 0;
  flex-shrink: 0;
  font: inherit;
  color: currentColor;
  width: 15px;
  height: 15px;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";

  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 10px 1px var(--form-control-color-checkbox);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: var(--form-control-color);
}
input[type="checkbox"]:checked::before {
  background-color: var(--form-control-color);
  transform: scale(1);
}
input[type="checkbox"]:checked:hover {
  background-color: white;
  transition: all ease 0.3s;
}
input[type="checkbox"]:checked {
  background-color: var(--form-control-color);
  border: var(--form-control-color);
}
.modal_question {
  cursor: pointer;
  opacity: 50%;
  transition: all ease 0.1s;
}
.modal_question:hover {
  opacity: 100%;
}
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.step {
  /* margin-top: 33px; */
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
}
.step__input {
  color: white;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  background-color: #2e2e38;
  border: 1px white solid;
  height: 50px;
  padding: 20px;
}
.step__input_notValid {
  border: 1px red solid;
  color: white;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  background-color: #2e2e38;
  height: 50px;
  padding: 20px;
}

.step__input:focus {
  border: 1px var(--form-control-color) solid;
  outline: none;
  transition: all ease 0.3s;
}
.title {
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.title2 {
  margin-bottom: 20px;
  cursor: default;
}
.step4 {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}

.checkbox__content {
  display: flex;
  flex-direction: row;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  font-family: "EyInterstate";
  margin-left: 15px;
  color: #ffffff;
  cursor: pointer;
}

.checkbox__content + .checkbox__content {
  margin-top: 10px;
}

.required__field {
  margin-top: 25px;
  margin-bottom: 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  font-family: "EyInterstate";
}
.checkbox__container {
  position: relative;
  margin-top: 2px;
}
/* .button__container {
  display: none;
} */

.checkbox-input-label a,
.checkbox-input-label a:visited {
  color: white;
}

.checkbox-declaration {
  padding-bottom: 20px;
}

.checkbox-declaration,
.checkbox-declaration * {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  font-family: "EyInterstate";
  color: #ffffff;
}

.checkbox-declaration a,
.checkbox-declaration a:visited {
  color: white;
}

.button_back {
  cursor: pointer;
  width: 100%;
  height: 40px;
  background-color: #2e2e38;
  color: #724bc3;
  border: 1px solid #724bc3;
  border-radius: 5px;
  margin-right: 15px;
}
.arrow_right::after {
  content: url(./assets/images/arrow_right.svg);
  vertical-align: top;
  position: relative;
  top: 1px;
  left: 7px;
}
.arrow_left::after {
  content: url(./assets//images/arrow_left.svg);
  vertical-align: top;
  position: relative;

  right: 12px;
}
.dual_button {
  display: flex;
  flex-direction: row-reverse;
}

@media (max-width: 1100px) {
  .successContainer {
    margin-right: 24px;
  }
  .steps {
    min-height: 100%;
  }
  .tab-subtitle-active {
    display: none;
  }

  .modal-content {
    align-self: flex-end;
    width: 100%;
    height: 90%;
  }
  .sub-moduleName {
    display: none;
  }
  .tabs {
    min-height: 53px;
    position: static;
    overflow-x: scroll;
    align-self: auto;
  }
  .button_next {
    background-color: #724bc3;

    margin-bottom: 15px;
  }
  .button_next:disabled {
    background-color: #77777d;
    pointer-events: none;
  }
  .body {
    padding-left: 24px;

    margin-top: 24px;
  }

  .module_list_element {
    display: flex;
    flex-direction: column;
    width: auto;
  }

  .tab-row {
    flex-direction: column;
  }
  .tab-title-active {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-inline: 5px;
  }
  .tab-active {
    display: flex;

    border-left: none;
    border-top: 3px #ffe600 solid;
    margin-bottom: 0px;
    padding: 8px 0px 0px 0px;
    margin-right: 8px;
    min-width: 70px;
  }

  .tab-subtitle {
    display: none;
  }
  .tab-title {
    padding-inline: 5px;
    justify-content: center;
  }
  .tabs {
    display: flex;
    flex-direction: row;
    margin: 0px;
    margin-top: 26px;
    border-bottom: 1px #51515c solid;
  }
  .top-nav {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .dots {
    /* padding-top: 25px; */
    margin-right: 0px;
  }
  .tab-inactive {
    display: flex;
    border-left: none;
    border-top: 3px rgba(156, 130, 212, 0.3) solid;
    margin-right: 8px;
    margin-bottom: 0px;
    padding: 8px 0px 0px 0px;
    /* min-width: 70px; */
    text-align: center;
  }

  .steps {
    padding: 0px;
    border-left: none;
  }
  .sub-title {
    border-bottom: none;
  }
  .headingclass {
    border-bottom: none;
  }
  .sub-title {
    margin-bottom: 0px;
  }
  .step {
    margin-right: 24px;
  }
  .tab-title {
    display: flex;

    font-size: 300;
    font-size: 12px;
    line-height: 16px;
  }

  .button__container {
    padding-right: 24px;
  }
  .dual_button {
    padding-right: 24px;

    flex-direction: column;
    width: 100%;
  }
}
.info_modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}
.modal-header-text {
  font-family: "EyInterstate";
  display: flex;
  justify-content: center;

  font-weight: 300;
  font-size: 20px;
  cursor: default;
  color: #ffe633;
}
.modal-content {
  overflow-y: auto;
  background-color: #1a1a24;
  max-height: 95vh;
  max-width: 768px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 32px 8px 32px;
  margin: 0px;
}
.modal-header {
  padding-bottom: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-content-text {
  cursor: default;
  display: flex;
  flex-direction: column;
  text-align: justify;

  color: #fdfdfd;
}

.modal-body {
  padding-top: 16px;
  border-top: 1px solid #51515c;
  display: flex;
  flex-direction: column;
}
.button_exit {
  opacity: 50%;
}
.button_exit:hover {
  transition: all ease 0.3s;
  cursor: pointer;

  opacity: 100%;
}
.announcement_body {
  padding: 25px 25px 63.5px 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #1a1a24;
  border-radius: 10px;
  border: 1px solid #51515c;
  align-items: center;
  margin-bottom: 25px;
}
.announcement_title {
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  display: flex;
  line-height: 24px;
  margin-bottom: 16px;
  color: #fdfdfd;
}
.announcement_context {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #f3f3f3;
}
.alertIcon_container {
  margin-bottom: 18px;
}
.modal_button {
  margin-top: 2px;
  align-self: center;
  align-items: center;
  background-color: #2e2e38;
  cursor: pointer;
  border: none;
}

.successHeadingContainer {
  display: flex;
  flex-direction: row;
}
.successHeading {
  color: #ffe600;
  font-weight: 300;
  font-size: 24px;
  margin-left: 18px;
  line-height: 26px;
  margin-bottom: 19px;
}
.successContent {
  margin-bottom: 30px;
}
.successContext {
  font-family: "EyInterstate";
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding-left: 47px;
  letter-spacing: -0.5px;
}
.successContainer {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
}
.successImg {
  height: 26px;
  width: 26px;
}
.loader {
  font-size: 10px;

  text-indent: -9999em;
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #6c48b7;

  background: -ms-linear-gradient(
    left,
    #6c48b7 10%,
    rgba(255, 255, 255, 0) 62%
  );
  background: linear-gradient(to right, #6c48b7 5%, rgba(255, 255, 255, 0) 55%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #6c48b7;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader:after {
  background: #1a1a24;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loader.initial {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader.initial:after {
  background-color: #2e2e38;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.modal_button_close {
  margin-top: 2px;
  background-color: #1a1a24;
  border: none;
  cursor: pointer;
}
.loading_modal_background {
  background-color: #1a1a24;
  padding: 25px;
  width: 464px;
  height: 215px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loading_modal_title {
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: white;
}
.loading_modal_subtitle {
  color: white;
  margin-top: 16px;
  font-size: 14px;
  padding-bottom: 26px;
  line-height: 20px;
  font-weight: 300;
  text-align: center;
}
.tab-subtitle-active {
  color: #ffe600;
  display: flex;
  min-width: max-content;
  flex-direction: column;
  font-weight: 400;
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 4px;
  line-height: 12px;
}
.label_div {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 43px;
}
.question__label {
  min-height: 43px;
}
.phone-module-display {
  display: none;
}
@media (max-width: 1100px) {
  .tab-subtitle-active {
    display: none;
  }

  .announcement_body {
    width: 100%;
    margin-right: 25px;
  }
  .phone-module-display {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    margin-bottom: 18px;
    padding-bottom: 6px;
  }
  .phone-submodule-name {
    font-weight: 300;
    display: flex;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
    font-family: "EyInterstate";
    margin-right: 16px;
    color: #77777d;
    align-items: center;
    min-width: max-content;
  }
  .phone-submodule-name-active {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    font-family: "EyInterstate";
    color: #ffe600;
    margin-right: 16px;
    min-width: max-content;

    display: flex;
  }
  .loading_modal_background {
    min-width: 100%;
    min-height: 100%;
  }
}
.label_div {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 43px;
}
.modal-body {
  min-width: 300px;
}
.input_container {
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 18px;
  margin-top: 2px;
  margin-right: 10px;
}
.content-h h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-size: 18px;
  line-height: 19.8px;

  letter-spacing: -0.5px;
  margin-bottom: 16px;
}

.content-p p {
  font-weight: 300;
  font-size: 16px;
  margin-right: 12px;
  line-height: 20px;
  letter-spacing: -0.5px;
  font-family: "EyInterstate";
  margin-bottom: 16px;
}
ul {
  list-style: none;
  padding: 0px;
}
ol {
  list-style: none;
  padding: 0px;
}
li::before {
  content: "";
  border-color: transparent white;
  border-style: solid;
  border-width: 0.35em 0 0.3em 0.45em;
  display: block;
  height: 0;
  left: -1em;
  top: 15px;
  position: relative;
}
li {
  line-height: 20px;
  letter-spacing: -0.5px;
  font-weight: 300;
  font-size: 14px;
  font-family: "EyInterstate";
  padding-left: 16px;
}
ul li ::marker {
  content: "▶";

  font-family: "EyInterstate";
}

.checkbox__input {
  margin-right: 18px;
  margin-top: 3px;
}
.sub-moduleName {
  font-family: "EyInterstate";
  margin-bottom: 25px;
  margin-top: 4px;
  font-weight: 400px;
  font-size: 12px;
  line-height: 20px;
  color: #ffe600;
}
.modal-inside-content {
  height: 100%;
  overflow-y: auto;
}
.modal_button:hover .modal_question {
  opacity: 100%;
}
.loader_container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 24px;
}

.submodule-info {
  margin-block: 24px;
}

.submodule-info-title {
  font-family: "EyInterstate";
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-weight: 300;
}

.submodule-info-description {
  font-family: "EyInterstate";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.5px;
  font-weight: 300;
  margin-top: 16px;
}

br {
  display: none;
}

ul > li::marker {
  list-style: none;
  content: none;
}
ol > li::marker {
  list-style: none;
  content: none;
}
.question__title > h2 {
  margin-bottom: 0px;
}
.modal-header > h4 {
  margin-bottom: 0px;
}

ul {
  margin-top: 0px;
}
.cookieModalHeader {
  font-family: "EyInterstate";
  display: flex;
  justify-content: center;
  font-weight: 300;
  font-size: 20px;
  cursor: default;
  color: #ffe633;
}
.cookies-radioButtonList {
  margin-top: 20px;
}
.cookie-checkboxContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
.cookie-checkboxContent {
  display: flex;
}
.cookie-checkbox {
  margin-top: 5px;
  margin-right: 20px;
}
.cookie-contentHeading {
  font-family: "EyInterstate";
  font-weight: 500;
  font-size: 18px;
  cursor: default;
  color: white;
}
.cookie-contentSubHeading {
  margin-top: 5px;
  font-family: "EyInterstate";
  font-weight: 300;
  font-size: 16px;
  cursor: default;
  color: white;
}
.cookies-buttonContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}
/* .container {
    scrollbar-width: none;
}
.container::-webkit-scrollbar {
    display: none;
} */
.cookies-buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cookies-button {
  cursor: pointer;
  outline: none;
  margin-top: 20px;
  width: 50%;
  font-size: 17px;
  font-family: "EyInterstate";
  font-weight: 700;
  height: 40px;
  background-color: #1a1a24;
  color: #ffda2a;
  border-radius: 5px;
  border: 0px;
  transition: color, 0.3s ease-in-out;
}
.cookies-button:hover {
  background-color: #1a1a24;
  color: white;
}
.cookies-table {
  margin-top: 20px;
  margin-bottom: 40px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}
.cookies-table th {
  font-family: "EyInterstate";
  font-weight: 300;
  font-size: 16px;
  cursor: default;
  color: #ffe633;
}
.cookies-table td {
  color: white;
  font-family: "EyInterstate";
  text-align: center;
}
.modal-content-cookies {
  background-color: #1a1a24;
  max-height: 90vh;
  width: 900px;
  display: flex;
  flex-direction: column;
  padding: 32px 32px 8px 32px;
  margin: 0px;
}

@media only screen and (max-width: 800px) {
  .cookie-table-wrapper {
    display: flex;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}
@media only screen and (max-width: 360px) {
  .cookies-button {
    padding: 5px;
    display: flex;
    width: fit-content;
    height: fit-content;
  }
}
a {
  transition: color, 0.3s ease-in-out;
}
.cookie-contentSubHeading a {
  color: #ffe633;
  transition: color, 0.3s ease-in-out;
}
.cookie-contentSubHeading a:hover {
  color: white;
}
.cookies-settings {
  position: fixed;
  bottom: 20px;
  right: 60px;
}
.cookies-settings-texts {
  color: #ffe633;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  transition: color, 0.3s ease-in-out;
  font-family: "EyInterstate";
}
.cookies-settings-texts:hover {
  color: white;
}
@media only screen and (max-width: 1024px) {
  .cookies-settings {
    display: flex;
    position: static;
    justify-content: center;
  }
}

/* ffda2a */
